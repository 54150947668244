import React, { useState, useRef, useEffect } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Import assets
import backgroundVideo from '../src/assets/background.mp4';
import logoImage from '../src/assets/logo-novaura.png';
import backgroundImage from '../src/assets/background.png';

// Environment variable for API endpoint
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// Utility functions
const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)})${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

const cleanPhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/\D/g, '');
};

const submitToApiGateway = async (data) => {
  try {
    const response = await axios.post(
      API_ENDPOINT,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      }
    );

    return { success: true, data: response.data };
  } catch (error) {
    console.error('API Error:', error.response || error);
    return { 
      success: false, 
      error: error.response ? error.response.data : error.message 
    };
  }
};

// Components
const LandingPage = () => {
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play().catch(error => {
        setVideoEnded(true);
      });
    }
    return () => {
      if (video) {
        video.pause();
        video.src = '';
        video.load();
      }
    };
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-black text-white relative overflow-hidden">
      {!videoEnded ? (
        <video
          ref={videoRef}
          autoPlay
          muted
          playsInline
          preload="auto"
          onEnded={() => setVideoEnded(true)}
          className="absolute z-0 w-auto min-w-full min-h-full max-w-none"
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      ) : (
        <div 
          className="absolute z-0 w-full h-full bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${backgroundImage})` }}
        />
      )}
      <div className="z-10 text-center">
        <img src={logoImage} alt="NOVAURA" className="mb-6 mx-auto" style={{ maxWidth: '400px' }} />
        <p className="mb-8 text-xl font-light">Solving your world's biggest business problems.</p>
        <Link
          to="/signup"
          className="bg-transparent border-2 border-white text-white px-8 py-3 rounded-full hover:bg-white hover:text-black transition-colors text-lg font-medium"
        >
          JOIN THE WAIT LIST
        </Link>
      </div>
    </div>
  );
};

const SignUpForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    company: '',
    message: ''
  });
  const [submitError, setSubmitError] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'phoneNumber' ? formatPhoneNumber(value) : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitError(null);

    if (!consentGiven) {
      setSubmitError('Please agree to the terms before submitting.');
      return;
    }

    // Clean the phone number before submission
    const cleanedFormData = {
      ...formData,
      phoneNumber: cleanPhoneNumber(formData.phoneNumber)
    };

    const result = await submitToApiGateway(cleanedFormData);
    if (result.success) {
      navigate('/thank-you');
    } else {
      setSubmitError('There was an error submitting your form. Please try again later.');
    }
  };

  return (
    <div 
      className="min-h-screen flex items-center justify-center p-4 bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        {['firstName', 'lastName', 'phoneNumber', 'email', 'company'].map((field) => (
          <div key={field} className="mb-4">
            <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor={field}>
              {field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1').trim()}
            </label>
            <input
              type={field === 'email' ? 'email' : 'text'}
              id={field}
              name={field}
              value={formData[field]}
              onChange={handleInputChange}
              placeholder={`Enter ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
              required
            />
          </div>
        ))}

        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2" htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder="..."
            rows="4"
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
          />
        </div>

        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={consentGiven}
              onChange={(e) => setConsentGiven(e.target.checked)}
              className="mr-2"
            />
            <span className="text-sm text-gray-700">
              By submitting this form, you consent to receive texts, emails, and phone calls from Novaura regarding our services. Message and data rates may apply. Reply STOP to cancel at any time.
            </span>
          </label>
        </div>

        {submitError && (
          <div className="mb-4 text-red-500">
            Error: {submitError}
          </div>
        )}

        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => navigate('/')}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

// Updated ThankYouPage component
const ThankYouPage = () => {
  return (
    <div 
      className="min-h-screen flex flex-col items-center justify-center bg-cover bg-center bg-no-repeat text-white"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="text-center mb-8">
        <h1 className="text-4xl font-light mb-4">
          THANK YOU FOR SIGNING UP.
        </h1>
        <h2 className="text-4xl font-light mb-4">
          WE WILL CONTACT YOU SOON!
        </h2>
      </div>
      <Link to="/" className="transition-transform hover:scale-105">
        <img src={logoImage} alt="NOVAURA" className="max-w-md w-full cursor-pointer" style={{ maxWidth: '400px' }} />
      </Link>
    </div>
  );
};

// Main App Component
const App = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route path="/signup" element={<SignUpForm />} />
    <Route path="/thank-you" element={<ThankYouPage />} />
  </Routes>
);

export default App;